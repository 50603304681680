@import '~@yeastar/pc-web-ui/src/style/variables.less';

.visit {
    height: 100%;
    .ant-spin-nested-loading,
    .ant-spin-container {
        height: 100%;
    }
    .visit-body-table {
        height: calc(100% - 44px);

        > .ant-result {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .ant-table-thead th.ant-table-column-has-sorters:hover {
        .ant-table-filter-trigger {
            background-color: #efefef;
        }
    }
    .ant-table-filter-trigger {
        width: 16px;
        background-color: #f7f7f7;
        transition: all 0.3s;
    }
    .ant-table-filter-trigger-container:hover {
        background-color: transparent;
    }
    .ant-table-filter-column {
        position: relative;
    }
    .ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column-title {
        padding-right: 16px;
    }
    .ant-table-filter-column-title {
        .ant-table-column-sorters {
            display: flex;
            flex: auto;
            justify-content: space-between;
            padding-right: 8px;
            > span:first-child {
                flex: 1;
            }
        }
    }

    .visit-title {
        display: flex;
        justify-content: space-between;
        margin-bottom: @margin-sm;
        flex: 1;
    }
    .icon-button {
        font-size: @font-size-sm;
        &:hover {
            color: @primary-color;
            cursor: pointer;
        }
    }
    .date-address-container {
        padding: 8px 12px 0;
    }

    .invite-table {
        .refresh-content {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .refresh-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 16px;
        }
    }
}

.ant-select-dropdown-empty {
    .ant-select-item-empty {
        text-align: center;
    }
}

.address-treeSelect {
    .ant-select-selector {
        max-height: 100px;
        overflow: auto;
    }
}
/** 调整日期选择器组件浮层样式 */
.date-picker-container {
    padding: 8px;
    .ant-picker-dropdown {
        top: 51px !important;
        left: 0 !important;
    }
    .ant-picker-range-arrow {
        display: none;
    }
    .ant-picker-dropdown-range {
        padding: 0;
    }
    .ant-picker-footer-extra {
        padding-top: 9px;
        padding-bottom: 9px;
    }
}
