/* 复写ant  全局样式  临时存放 */
.desk-form-wrapper
.ant-form-item-label
> label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none !important;
}
.inline-repeat{
    .schedule-setting-timezone {
        font-weight: 400;
        font-size: 12px;
        color: #00000099;
        line-height: 16px;
        padding-bottom: 4px;
    }
    .ant-form-item-label label{
        margin-top: -8px;
    }
}
.desk_button_animation {
    position: absolute;
    top: 50%;
    left: calc(100% - 10px);
    transform: translate(-50%, -50%);
    pointer-events: none;
}

.time-error-note {
    margin-top: 3px;

    & &-icon {
        color: #FE8C00;
        vertical-align: top;
        position: relative;
        top: 1.5px;
    }

    &-text {
        color: #00000061;
        font-size: 12px;
        margin-left: 4px;
        display: inline-block;
        vertical-align: top;
        max-width: calc(100% - 29px);
        position: relative;
        top: 1px;
    }
}
.ant-form {
    .desk-name-select {
        .ant-select-arrow {
            z-index: 1;
        }
    }
}
