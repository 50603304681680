@import '~@yeastar/pc-web-ui/src/style/variables.less';

.free-trial-wrapper {
    padding: 24px;
    p {
        color: rgba(0, 0, 0, 0.6);
        font-size: 14px;
    }
}
.free-trial-title.ant-typography {
    margin-bottom: 8px;
}
.free-trial-guide-video {
    position: relative;
    font-size: 0;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.04), 0 6px 12px 12px rgba(0,0,0,0.04), 0 6px 10px 0 rgba(0,0,0,0.08);
    iframe {
        border-radius: 10px;
    }
}
.free-trial-close-btn {
    position: absolute;
    top: -14px;
    right: -14px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    color: #fff;
    background-color: @blue-base;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color .3s;
    &:hover {
        background-color: @blue-5;
    }
}
