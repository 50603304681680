.week-calender,
.month-calender {
    .rbc-time-gutter {
        .time-gutter-content {
            .gutter-icon-up {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, -100%);
            }
            .gutter-icon-down {
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate(-50%, 100%);
            }
        }
    }
}

.week-new-calender,
.month-new-calender {
    .rbc-time-gutter {
        .time-gutter-content {
            width: 100%;
            .gutter-icon-up {
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(50%, -50%) rotate(-90deg);
            }
            .gutter-icon-down {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(-50%, -50%) rotate(-90deg);
            }
        }
    }
}
.week-new-calender,
.month-new-calender,
.week-calender,
.month-calender {
    .rbc-time-gutter {
        .rbc-timeslot-group {
            display: flex;
            cursor: pointer;
            &:hover {
                background: #0000000a;
            }
            .meet-time-gutter {
                display: flex;
                flex: 1;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .time-gutter-content {
                    position: relative;
                    width: 100%;
                    p {
                        margin: 0;
                        color: #00000099;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        white-space: pre-wrap;
                        text-align: center;
                    }
                    .anticon {
                        color: #00000099;
                    }
                }
                .current-day {
                    p,
                    .anticon > svg {
                        color: #006ee1;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}
