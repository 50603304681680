.conference-detail {
    // width: 100% !important;

    .meeting-form-wrapper {
        min-width: 480px;
        height: 100%;
        border-right: 1px solid #f2f2f2;
    }
}

// 会议编辑模式下，modal mask 会出现滚动条，需要隐藏
.conference-detail-modal-wrapper.ant-modal-wrap {
    overflow: hidden;
}

.meet-edit-popover--unfold.conference-detail {
    position: absolute;

    width: 100% !important;
    max-width: 100% !important;
    height: calc(100% - 56px);

    .ant-modal-content {
        border-radius: 0 !important;
    }
    .ant-modal-content,
    .ant-modal-body,
    .meeting-conflict,
    .ant-row.meeting-modal-container,
    .ant-form {
        height: 100%;
    }
    .meet-edit-popover-content {
        height: calc(100% - 64px);
    }
    .ant-row.meeting-modal-container {
        .meeting-conflict {
            max-width: calc(100% - 489px);
        }
    }
    .meet-edit-popover-footer {
        padding: 16px 24px;
    }
}
