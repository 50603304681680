.custom-repeat-modal {
    .custom-repeat-modal-content {
        padding: 16px 24px;
        .ant-form-vertical .ant-form-item {
            flex-direction: row;
        }
    }

    .WeekSelect {
        display: flex;
        justify-content: space-between;

        .ant-checkbox-group-item {
            margin-right: 0px;
            .ant-checkbox {
                display: none;
            }
            .ant-checkbox-wrapper {
                //   height: 28px;
            }

            .ant-checkbox + span {
                display: block;
                width: 32px;
                height: 32px;
                padding: 0;
                color: rgba(0, 0, 0, 0.87);
                line-height: 32px;
                text-align: center;
                background: #ffffff;
                border: 1px solid rgba(0, 0, 0, 0.13);
                border-radius: 50px;
            }
            &.ant-checkbox-wrapper-checked {
                .ant-checkbox + span {
                    background: #006ee1;
                    color: #ffffff;
                }
            }
        }
    }
}
