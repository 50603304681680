.desk-edit-popover {
    width: 540px;
    padding: 0;
    .ant-popover-inner {
        border-radius: 8px;
        .ant-popover-inner-content {
            padding: 0;
            .desk-modal-container {
                display: block;
                .desk-form-wrapper {
                    width: 100%;
                    .desk-edit-popover-content {
                        padding: 24px 24px 0;
                        .desk-edit-popover-title {
                            display: flex;
                            justify-content: space-between;
                            margin-bottom: 16px;
                            h5.ant-typography {
                                margin-bottom: 0;
                            }
                        }
                        .desk-form-item-wrapper {
                            .inline-clock {
                                // margin-bottom: 0;
                                .inline-clock-item-l {
                                    display: inline-block;
                                    width: 116px;
                                }
                                .inline-clock-item-s {
                                    display: inline-block;
                                    width: 92px;
                                    .select-hide {
                                        display: none;
                                    }
                                }
                                .ant-form-item-explain.ant-form-item-explain-error {
                                    margin-top: -14px;
                                }
                                .line-Space {
                                    width: 100%;
                                    .ant-space {
                                        width: 100%;
                                        .ant-space-item {
                                            width: calc(50% - 4px);
                                        }
                                    }
                                }
                            }
                            .inline-clock-line {
                                margin-bottom: 16px;
                            }
                            .select-hide {
                                display: none;
                            }
                            .inline-clock-item-s {
                                display: inline-block;
                                width: 70px;
                                .select-hide {
                                    display: none;
                                }
                            }

                            //.ant-form-item-no-colon {
                                //&::before {
                                //    display: inline-block;
                                //    margin-right: 4px;
                                //    font-size: 14px;
                                //    font-family: SimSun, sans-serif;
                                //    line-height: 1;
                                //    content: '*';
                                //    visibility: hidden;
                                //}
                            //}
                            .ant-form-item-required {
                                &::before {
                                    visibility: visible;
                                }
                            }
                        }
                    }
                    .desk-edit-popover-footer {
                        display: flex;
                        //padding: 8px 24px;
                        border-top: 1px solid rgba(0, 0, 0, 0.05);
                        justify-content: flex-end;
                    }
                }
            }
        }
    }
}
