@import '~@yeastar/pc-web-ui/src/style/variables.less';
.meetingName{
    font-weight: 600;
    font-size: @font-size-base;
    color: @gray-base;
    line-height: 20px;

}
.infoIcon{
    color: @gray-5 !important;
    font-size: @font-size-sm;
    margin-left: @margin-xss;
    cursor: pointer;
}
.moreInfoPopover{
    z-index: 1051;
    :global(.ant-popover){
        z-index: 1051;
    }
}
.item{
    line-height: 20px;
    .meeting-inline-box {
        display: flex;
        // max-width: 320px;
        margin-top: @margin-xss;
        font-size: @font-size-xs;
        line-height: 16px;
        color: @gray-7;
        .icon-class {
            margin-right: 2px;
            margin-top: 1px;
            font-size: @font-size-base !important;
        }
        .ant-typography {
            color: @gray-7;
        }
        .meeting-text{
            display: inline-block;
            max-width: 440px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        :global{
            .ant-typography{
                color: @gray-7;
            }
        }
    }
    .unMatching{
        color: @warning-color;
        :global{
            .ant-tag{
                border: 1px solid @warning-color;
            }
            .ant-typography{
                color: @warning-color;
            }

        }
    }
}
