.icon-btn-wrapper {
    display: flex;
    align-items: center;

    & &-icon {
        width: 32px;
        height: 32px;
        color: #fff;
        background-color: rgba(255, 255, 255, 0.13);
        border-radius: 50%;

        &:hover {
            background-color: rgba(255, 255, 255, 0.07);
        }

        &:active {
            background-color: rgba(255, 255, 255, 0.13);
        }
    }

    .notification-wrapper-badge {
        .ant-badge-count {
            min-width: 16px;
            height: 16px;
            padding-right: 6px;
            padding-left: 5px;
            color: rgba(255, 255, 255, 0.87);
            font-size: 12px;
            line-height: 16px;
            background-color: rgb(254, 140, 0);
            border-radius: 8px;
            box-shadow: none;
        }
        .ant-badge-multiple-words {
            padding-right: 7px;
        }
    }
}
