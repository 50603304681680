@import '~@yeastar/pc-web-ui/src/style/variables.less';

.visit-approval {
    /** 页面滚动table */
    height: 100%;
    margin-top: -24px;
    margin-right: -24px;
    padding-top: 24px;
    padding-right: 24px;
    overflow: auto;
    .option-btn {
        min-width: auto;
        padding: 0;
        color: #006ee1;
        cursor: pointer;
    }
    .icon-button {
        font-size: @font-size-sm;
        &:hover {
            color: @primary-color;
            cursor: pointer;
        }
    }
    .ant-table-filter-trigger {
        background-color: #f7f7f7;
    }

    /** 调整table column样式 */
    .ant-table-thead th.ant-table-column-has-sorters:hover {
        background: #f7f7f7;
    }
    .ant-table-filter-trigger .anticon {
        margin-top: 1px;
        margin-left: -5px;
    }

    .ant-table-filter-trigger.active {
        border-bottom: none;
    }

    .ant-table-filter-column-title .ant-table-column-sorters {
        display: flex;
        flex: auto;
        justify-content: space-between;
        padding-right: 8px;
        padding-left: 0;
    }

    .ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column-title {
        padding-right: 16px;
    }

    .ant-table-filter-column-title .ant-table-column-sorters > span:first-child {
        flex: 1 1;
    }
}
.header-box {
    margin-bottom: 12px;
}