@import '~@yeastar/pc-web-ui/src/style/variables.less';
.recommendModal{
    padding-bottom: @padding-md;
    .descIcon{
        color: @gray-7;
    }
    .header{
        font-weight: 600;
        font-size: @font-size-sm;
        color: #000000de;
        padding-bottom: @padding-xs;
    }
    .filter{
        .selectArea{
            width: 100%;
        }
    }
    .recommend{
        .header{
            padding-top: @padding-lg;
        }
        .content{
            border: 1px solid @gray-3;
            border-radius: @border-radius-xs;
            overflow: hidden;
            margin-bottom: @margin-md;
            .sort{
                height: 40px;
                padding-left: @padding-md;
                border-bottom: 1px solid @gray-2;
                .sortOperate{
                    display: inline-flex;
                    align-items: center;
                    height: 100%;
                    color: @gray-base;
                    cursor: pointer;
                }
            }
        }
        .room:hover{
            background-color: #f5f5f5;
            :global{
                .ant-tag{
                    background-color: #f5f5f5;
                }
            }
        }
        .roomItem{
            display: flex;
            align-items: center;
            padding: @padding-sm @padding-md @padding-sm 0;
            border-bottom: 1px solid @gray-2;
            margin-left: @margin-md;
            cursor: pointer;
            .left{
                margin-right: @margin-md;
            }
            .right{
                flex: 1;
            }
        }
        .room:last-child .roomItem{
            border-bottom: none;
        }
        .selected{
            background-color: @blue-1;
            :global{
                .ant-tag{
                    background-color: @blue-1 !important;
                }
            }
        }
    }
    :global{
        .ant-alert {
            padding: @padding-sm @padding-md;
            line-height: 20px;
        }
        .ant-alert-warning .ant-alert-message{
            color: @warning-color;
        }
        .ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active{
            color: #FFF;
            background: @blue-3;
            border-color: transparent;
        }
        .ant-modal-footer {
            padding-top: 10px;
        }
        .ant-spin-nested-loading > div > .ant-spin {
            top: 35px;
        }
        .ant-select-arrow {
            color: @gray-5;
        }
    }
    .recommendContent{
        display: flex;
        flex-direction: column;
        max-height: calc(100vh - 260px);
        .roomList{
            min-height: 90px;
        }
    }
}

