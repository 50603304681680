.layout-body {
    width: 100vw;
    height: 100vh;
    .layout-content {
        // height: 100%;
        flex: 1;
        overflow: hidden;
        .ant-layout {
            height: 100%;
        }
        .ant-layout-content {
            height: 100%;
            padding: 24px 24px 16px 24px;
        }
    }
    .header-tab-wrapper a {
        color: #fff;
        &:hover {
            color: #fff;
        }
    }
    .layout-active-tab {
        padding-bottom: 4px;
        color: #fff !important;
        font-weight: bold;
        border-bottom: 2px solid #fff;
    }
}

// 菜单 popup 样式自定义
.header-submenu-overflowed-custom-class {
    ul[role="menu"] {
        padding: 4px 0;

        .ant-menu-item {
            &:hover {
                background-color: rgba(0, 0, 0, 0.04);
            }

            a:hover {
                color: rgba(0, 0, 0, 0.87);
            }
        }

    }
}
